.dash-card {
	--background: #fff;
	border-radius: 24px;
	padding-bottom: 44%;
	background-image: url(../../assets/images/dashboard-02.png);
	background-repeat: no-repeat;
	background-position: 50% 150px;
	background-size: 100% auto;
	box-shadow: 0 0 32px rgba(0,0,0,0.25);
}

.dash-menu {
	padding: 0 64px;
}

.dash-menu .list-md {
	background: transparent;
}

.dash-menu ion-item {
	--color: var(--ion-color-primary);
	--background: trasparent;
	--background-hover: trasparent;
	--min-height: 32px;
}

.dash-menu ion-item {
	--inner-border-width: 0 0 2px 0;
	--inner-padding-end: 0px;
}

.dash-card ion-card-title, .dash-menu ion-item {
	font-weight: bold;
}

.dash-menu ion-label {
	text-align: center;
	margin-right: 0;
}

.poster.dashboard-01 {
	margin-bottom: -70px;
	background-image: url(../../assets/images/dashboard-01.png);
	background-size: cover;
}
