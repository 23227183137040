ion-input.okx-password,
ion-input.android-okx-password {
	flex-direction: row-reverse;
}
ion-input.okx-password input {
	padding-top: 25px!important;
}
ion-input.android-okx-password input {
	padding-top: 28px!important;
}
.password-label,
.android-password-label {
	position: absolute;
	left: 0;
	margin-top: 0;
}
ion-input.android-okx-password.has-focus ion-label.android-password-label,
ion-input.android-okx-password.has-value ion-label.android-password-label {
	top: 0;
}
ion-input.okx-password ion-button {
	margin-top: 20px;
	--background: transparent;
	--background-activated: transparent;
	--background-focused: transparent;
	--background-hover: transparent;
	--ripple-color: transparent;
}
ion-input.android-okx-password ion-button {
	margin-top: 20px;
}