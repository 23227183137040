.validate-button-wrapper {
    height: 45px;
}

.validate-button-wrapper ion-row {
    width: 165px;
    margin: 0 auto;
    border-radius: 20px;
    border: 1px solid var(--ion-color-gray);
    margin-bottom: 25px;
    color: var(--ion-color-gray);
}

.validate-button-wrapper ion-row ion-col div ion-icon {
    font-size: 19px;
    color: var(--ion-color-gray);
}

.validate-button-wrapper ion-row ion-col.verified-right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: var(--okx-small-text-font-size);
    flex-grow: 3;
 }