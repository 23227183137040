.history-content {
    text-align: center;
    padding: 20px;
}

.history-item {
    border-bottom: 1px solid var(--ion-separator-color);
    text-transform: uppercase;
}

.history-item h2 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 15px;
    font-weight: bold;
}

.history-item ion-col:first-child {
    text-align: left;
}

.history-item ion-col p:first-child {
    color: var(--ion-color-primary);
    font-size: 8px;
    margin-bottom: 8px;
    font-weight: bold;
}

.history-item ion-col p:last-child {
    color: var(--ion-color-medium);
    font-size: 9px;
    margin-top: 2px;
    margin-bottom: 10px;
}

.history-item ion-col:last-child p {
    text-align: right;
    font-size: 15px;
    margin-top: 25px;
    font-weight: bold;
    color: var(--ion-color-tertiary);
}
