.poster.login-01 {
	background-size: 90% auto;
	position: relative;
	background-color: #fff;
	background-image: url(../../assets/images/login-02.png);
	background-position: 0 125%;
	z-index: 1;
}
.poster.login-01::after {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: inherit;
	background-image: url(../../assets/images/login-01.png);
	background-color: transparent;
	background-position: 50% 0;
	background-size: 180px auto;
	z-index: 2;
}
.login-icon {
	margin-right: 15px;
	margin-top: 29px;
	color: var(--ion-color-primary);
}
.login-button {
	margin-top: 40px;
}
.text-link {
	margin-top: 0;
	height: 20px;
	margin-bottom: 40px;
}
.forgot-pass {
	font-size: 10px !important;
}
.login-header {
	color: var(--ion-color-dark);
}
.login-input ion-label,
.android-login-input ion-label,
.password-label,
.android-password-label {
	position: absolute;
}
.android-login-input ion-label,
.android-password-label {
	top: 12px;
}
.android-login-input.has-focus ion-label,
.android-login-input.has-value ion-label {
	top: 0;
}
.password-label{ 
	margin-top: 0;
}
.login-input input {
	padding-top: 25px!important;
}
.android-login-input input {
	padding-top: 28px!important;
}
.login-input button,
.android-login-input button {
	margin-top: 18px!important;
}