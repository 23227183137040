.validate-modal > .modal-wrapper {
	max-height: 72vh;
	width: 80vw;
    border-radius: 15px;
    text-align: center;
}

.validate-modal > .modal-wrapper > .ion-page {
	min-width: 100%;
	padding: 15px 25px;
	overflow: auto;
}

.validate-modal > .modal-wrapper > .ion-page h2 {
	font-size: 20px;
	margin-top: 4px;
	margin-bottom: 4px;
}

.validate-modal > .modal-wrapper > .ion-page ion-icon {
	position: absolute;
	right: 0;
	font-size: 15px;
}

.validate-modal ion-item {
	--min-height: 20px;
	--inner-padding-end: 0;
}

.modal-content {
	margin-top: 20px;
}
