.menu-enabled .list-md {
	padding-top: 0;
	padding-bottom: 0;
}

.drawer-menu .header-md, .drawer-menu .header-md {
	background-color: var(--ion-color-primary);
}

.drawer-menu .header-md {
	padding: 32px 32px 0;
}

.drawer-menu .title-default {
	text-transform: uppercase;
	font-weight: bold;
}

.drawer-menu > .ion-color {
	--padding-start: 32px;
	--padding-end: 32px;
}

.drawer-menu .title-default {
	padding-inline-start: 0;
}

.drawer-menu .nav-item {
	--padding-start: 0;
	--min-height: 24px;
}

.drawer-menu .nav-icon, .drawer-menu .nav-label {
	margin-top: 8px;
	margin-bottom: 8px;
}

.drawer-menu .header-ios {
	padding: 20px 25px 0px 0px;
	background-color: var(--ion-color-primary);
}

.header-ios ion-toolbar:last-child{
	--border-width: 0 0 0px
} 

.footer-ios ion-toolbar:first-child {
    --border-width: 0 0 0px;
}

ion-footer ion-toolbar.app-android-version:last-child {
	padding: 0px 0px 0px 15px;
}	

ion-footer ion-toolbar.app-ios-version:last-child {
	padding: 0px;
}	

ion-footer ion-label.title{
	padding: 0px 0px 0px 32px;
}	

ion-footer ion-label.title-andorid{
	padding: 0px 0px 0px 20px;
}

.logo-5l {
	position: absolute;
	right: 16px;
	bottom: 8px;
}

.logo-5l > div {
	width: 70px;
	padding-top: 45%;
	background: url(../../assets/images/5loyalty_logo.svg) transparent no-repeat 50% 50%;
	background-size: 100% auto;
}