ion-header.static {
	margin-bottom: -48px;
	z-index: 5;
}

ion-toolbar.primary-toolbar {
	--min-height: 80px;
	--background: var(--ion-color-primary) url(../../assets/images/logo-main.png) no-repeat 50% 0/auto 80%;
}

ion-header.static ion-toolbar:last-child {
	--min-height: var(--ion-toolbar-min-height);
}

.image-button {
	flex: 1;
	background: transparent;
}
