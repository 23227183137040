.tabs {}
.tabs .segment-holder {
  position: relative;
}

/* .tabs ion-segment-button {
  min-width: 70px;
} */

.tabs ion-slides, ion-slide {
  height: calc(100vh - 179px);
}
