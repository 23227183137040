.square {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.square:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.content {
    position: absolute;
    display: flex;
    flex-flow: column;
    background-color: var(--ion-color-light);
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.soc-icon {
    display: flex;
    border-radius: 50%;
    width: 40%;
    height: 40%;
    background-color: var(--ion-color-primary);
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
    font-size: 40px;
}

.soc-label {
    font-size: 20px;
    font-weight: bold;
    color: var(--ion-color-dark);
}

.noSocial {
    display: flex;
    justify-content: center;
}
