.avatar-box {
    height: 97px;
    margin-bottom: 20px;
}
.input-avatar {
    display: none;
}
.profile-image-content {
    width: 96px;
    height: 96px;
    margin: auto;
}