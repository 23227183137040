
.gm-holder {
	position: absolute;
	left: 0; right: 0; top: 0; bottom: 0;
}

.map-holder {
	position: relative;
	height: 100%;
}

.map {
    width: 100%;
    height: 100%;
}

.gm-style .map-location-holder {
	z-index: 9999999;
}

.map-location-holder {
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
    top: 60%;
    background-color: var(--ion-color-box-holder);
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	z-index: 1;
}

.location-section ion-item {
    --color: var(--ion-color-dark);
    --padding-start: 0px;
    --border-color: var(--ion-separator-color);
}

.location-section h2 {
    margin-left: 10px;
}

.location-section p {
    margin-left: 10px;
    color: var(--ion-color-dark);
    font-size: 11px;
    line-height: 20px;
}

.location-info  {
    width: 100%;
    display: grid;
    /* grid-template-rows: repeat(5, 1fr); */
    grid-template-columns: repeat(2, 1f);
    padding-left: 10px;
    font-size: 11px;
    line-height: 20px;
}

ion-list.location-section ion-item:last-child {
    --padding-start: 0px;
    --border-width: 0px;
    --border-color: #fff;
}

.h-padded {
	padding: 0px 10px
}

.time {
    display: flex;
}

.time.time-0 {
    order: 1;
}
.time.time-1 {
    order: 3;
}
.time.time-2 {
    order: 5;
    grid-column-end: span 2;
}
.time.time-3 {
    order: 7;
    grid-column-end: span 2;
}
.time.time-4 {
    order: 9;
}
.time.time-5 {
    order: 2;
}
.time.time-6 {
    order: 4;
}

.time:nth-last-child(-n+3) {
    margin-right: 0px;
}

.day {
    flex: 0 0 35px;
}
 