.loyalty-content {
	text-align: center;
	padding: 20px;
}

.qr-holder {
	margin-top: 40px;
}

.loyalty-content h1 {
	margin-top: 0px;
	padding-top: 0px;
	font-size: 60px;
	font-weight: bold;
}

.loyalty-content.scan h2 {
	margin-top: 20px;
}

.loyalty-content h2 {
	margin-top: 0px;
	font-size: 15px;
	font-weight: bold;
	text-transform: uppercase;
}

.loyalty-content ion-note {
	display: block;
	margin-bottom: 20px;
	margin: 0 auto;
	width: 55%;
}

.loyalty-content ion-button {
	margin: 0 20px 20px;
}

.loyalty-content ion-card {
	position: relative;
}

.loyalty-content ion-card-title {
	font-weight: bold;
	font-size: 22px;
}

.loyalty-content ion-card-subtitle {
	text-transform: none;
}

.loyalty-content .cover {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: var(--ion-color-dark);
	opacity: 0.6;
}

.loyalty-content .cover ion-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	font-size: 50px;
	margin-left: -25px;
	margin-top: -25px;
}

.loyalty-image {
	--background: #fff;
	border-radius: 0px;
	padding-bottom: 35%;
	background-repeat: no-repeat;
	background-position: center top;
	background-size: 100% auto;
	box-shadow: none;
}

.loyalty-image-01 {
    background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	padding-bottom: 35%;
}

.noQrCode {
    display: flex;
	justify-content: center;
	margin-top: 20px;
}

.order-intro-modal > .modal-wrapper {
	--max-height: 450px;
	width: 300px;
	border-radius: 15px;
}

.order-intro-modal > .modal-wrapper {
	height: 390px;
	width: 300px;
	border-radius: 15px;
}

.order-intro-modal > .modal-wrapper > .ion-page {
	justify-content: center;
}

.header {
	background-color: var(--ion-color-primary);
}

.modal-text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 15px;
	overflow-y: auto;
}

.modal-button {
	margin-top: 35px;
	padding: 0 15px;
}
