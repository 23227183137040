@import url('variables.css') screen;

.ion-color-favorite {
	--ion-color-base: #69bb7b;
	--ion-color-base-rgb: 105, 187, 123;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-twitter {
	--ion-color-base: #1da1f4;
	--ion-color-base-rgb: 29, 161, 244;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: #1a8ed7;
	--ion-color-tint: #34aaf5;
}

.ion-color-google {
	--ion-color-base: #dc4a38;
	--ion-color-base-rgb: 220, 74, 56;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: #c24131;
	--ion-color-tint: #e05c4c;
}

.ion-color-vimeo {
	--ion-color-base: #23b6ea;
	--ion-color-base-rgb: 35, 182, 234;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: #1fa0ce;
	--ion-color-tint: #39bdec;
}

.ion-color-facebook {
	--ion-color-base: #3b5998;
	--ion-color-base-rgb: 59, 89, 152;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: #344e86;
	--ion-color-tint: #4f6aa2;
}

.ion-color-primary-shade {
	--ion-color-base: var(--ion-color-primary-shade);
	--ion-color-base-rgb: 185, 3, 11;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: var(--ion-color-primary-shade);
	--ion-color-tint: var(--ion-color-primary);
}

.ion-color-white {
		--ion-color-base: var(--ion-color-white);
		--ion-color-base-rgb: var(--ion-color-white-rgb);
		--ion-color-contrast: var(--ion-color-white-contrast);
		--ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
		--ion-color-shade: var(--ion-color-white-shade);
		--ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-gray, .ion-color-grey {
	--ion-color-base: var(--ion-color-gray);
	--ion-color-base-rgb: var(--ion-color-gray-rgb);
	--ion-color-contrast: var(--ion-color-gray-contrast);
	--ion-color-contrast-rgb: var(--ion-color-gray-contrast-rgb);
	--ion-color-shade: var(--ion-color-gray-shade);
	--ion-color-tint: var(--ion-color-gray-tint);
}

/* Shared Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the CSS variables found in Ionic's source files.
// To view all the possible Ionic variables, see:
// https://beta.ionicframework.com/docs/theming/css-variables#ionic-variables
*/

.md {
	--ion-toolbar-background: var(--ion-color-primary);
	--ion-toolbar-color: #fff;
	--ion-toolbar-color-activated: #fff;
}

h3 {
	font-size: 16px;
	text-align: center;
	color: var(--ion-color-tertiary);
	margin-bottom: 30px;
}

h4 {
	font-size: 15px;
	color: var(--ion-color-form-label-dark);
}

.title2 {
	color: var(--ion-color-title2);
	margin: 40px 0 20px;
}

.box-holder {
	background-color: var(--ion-color-box-holder);
	border-radius: 5px;
	padding: 0px 15px;
}


.pad10 {
	padding: 10px;
}

.pad20 {
	padding: 20px;
}

.pad40b {
	padding-bottom: 40px;
}

.pad5l {
	padding-left: 5px;
}

.pad17l {
	padding-left: 17px;
}

.box-holder ion-item {
	--padding-start: 0px;
	--border-color: var(--ion-separator-color)
}

.box-holder ion-list {
	margin-bottom: 10px
}

ion-list ion-item:last-child:not(.single-item) {
	--padding-start: 0px;
	--border-width: 0px;
	--border-color: #fff;
}

.single-item {
	margin-bottom: 10px;
}

ion-label h2 {
	color: var(--ion-color-form-label-dark);
}

ion-note {
	color: var(--ion-color-note);
	font-size: 12px;
}

ion-label.ion-text-wrap {
	line-height: 1 !important;
}

ion-item {
	--padding-start: 0;
}

ion-item ion-button {
	margin-bottom: 0;
	height: auto;
}

.float-input-holder {
	--padding-start: 0px;
	--border-color: var(--ion-color-form-label)
}

.top-small {
	margin-top: var(--ion-top-small);
}

.top-medium {
	margin-top: var(--ion-top-medium);
}

.verified-content {
	width: 190px;
	margin: 0 auto;
	text-align: center;
}

ion-button {
	--border-radius: 20px;
	--box-shadow: none;
	margin-bottom: 10px;
	height: 40px;
	font-size: 10px;
	font-weight: bold;
	text-transform: uppercase;
}

ion-button.ion-color-primary {
	--border-style: solid;
	--border-width: 2px;
	--border-color: var(--ion-color-primary);
}

ion-button.ion-color-white {
	--border-style: solid;
	--border-width: 2px;
	--border-color: var(--ion-color-white-contrast);
}

ion-button.validation {
	--background: var(--okx-color-white);
    --color: var(--ion-color-primary);
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--ion-color-primary);
}

ion-header ion-title {
	text-transform: uppercase;
	font-weight: bold;
}

.mixed-right, .mixed-right * {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	align-self: center;
	justify-self: flex-end;
}

.mixed-left, .mixed-left * {
	text-align: left;
	justify-content: flex-start;
	align-items: center;
	align-self: center;
	justify-self: flex-start;
}

.lefted, .lefted * {
	text-align: left;
	justify-content: flex-start;
	align-items: flex-start;
	align-self: flex-start;
	justify-self: flex-start;
}

.mixed-left , .mixed-left * .grow{ 
	flex-grow: 0.5;
}

.contrast-separator {
	display: block;
	height: 0;
	line-height: 0;
	border: 1px solid rgba(255,255,255,0.3);
	margin: 16px 0;
}

.contrast-separator.contrast-separator-mini {
	margin: 12px 0;
}

.poster {
	padding-top: 65%;
	background-color: transparent;
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.no-padding {
	padding: 0;
}

.text-link {
	--ion-toolbar-background: transparent;
	--background-hover: transparent;
	--background-activated: transparent;
	--background-focused: transparent;
	--color: var(--ion-color-medium);
	--color-hover: var(--ion-color-dark);
	--color-activated: var(--ion-color-dark);
	--color-focused: var(--ion-color-dark);
	--ripple-color: #fff;
	text-transform: unset;
	font-size: inherit;
}

.segment-holder {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.segment-holder ion-segment {
	flex: 0 0 auto;
}

.segment-holder .segment-content {
	flex: 1 1 auto;
	overflow: hidden;
	overflow-y: auto;
}

ion-segment-button {
	height: 50px;
	border-radius: 0px;
	color: var(--ion-color-dark);
	border: 0 none;
	border-bottom: 4px solid var(--ion-color-primary-contrast);
	background-color: var(--ion-color-primary-contrast);
	font-weight: bold;
}

ion-segment-button.segment-button-checked {
	border-bottom-color: var(--ion-color-primary);
	color: var(--ion-color-primary);
}

.clickable {
	cursor: pointer;
}

.field-error {
	color: var(--ion-color-primary);
	font-size: 11px;
	margin-bottom: 10px;
}

.small-text {
	font-size: var(--okx-small-text-font-size);
	color: inherit;
}
