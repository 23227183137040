.feedback-label {
    font-size: 10px;
    color: var(--ion-feedback-label);
    margin: 5px;
    font-weight: bold;
  }
  
  .star-holder {
    padding: 10px 0px;
  }
  
  .star {
    font-size: 26px;
    color: var(--ion-feedback-star);
    margin-right: 15px;
  }
  
  .box-holder-top {
    margin-top: 20px;
  }

  .separator {
    height: 1px;
    background-color: var(--ion-color-form-label-dark);
    margin: 0 5px;
}

.native-textarea.sc-ion-textarea-ios  {
  padding-inline-start: 5px;
}

.commentService {
  height: 50px;
}

.commentTech {
  height: 160px;
}

ion-popover.customSelectOptions .popover-wrapper .popover-content ion-select-popover ion-list {
  margin-bottom: 0px;
}

ion-popover.customSelectOptions .popover-wrapper .popover-content ion-select-popover ion-list ion-label{
  margin-left: 10px;
}
