.header-md::after {
	background-image: none;
}

ion-toolbar.dynamic-header {
	--min-height: var(--ion-toolbar-min-height);
	--ion-toolbar-background: transparent;
	--ion-toolbar-color: #fff;
}

#main > ion-content {
	z-index: 5;
}

.ion-page {
    min-width: 285px;
}

.dash-card {
	--background: #fff;
	border-radius: 24px;
	padding-bottom: 44%;
	background-image: url(/static/media/dashboard-02.f03aedc7.png);
	background-repeat: no-repeat;
	background-position: 50% 150px;
	background-size: 100% auto;
	box-shadow: 0 0 32px rgba(0,0,0,0.25);
}

.dash-menu {
	padding: 0 64px;
}

.dash-menu .list-md {
	background: transparent;
}

.dash-menu ion-item {
	--color: var(--ion-color-primary);
	--background: trasparent;
	--background-hover: trasparent;
	--min-height: 32px;
}

.dash-menu ion-item {
	--inner-border-width: 0 0 2px 0;
	--inner-padding-end: 0px;
}

.dash-card ion-card-title, .dash-menu ion-item {
	font-weight: bold;
}

.dash-menu ion-label {
	text-align: center;
	margin-right: 0;
}

.poster.dashboard-01 {
	margin-bottom: -70px;
	background-image: url(/static/media/dashboard-01.0b8c86d5.png);
	background-size: cover;
}

.validate-button-wrapper {
    height: 45px;
}

.validate-button-wrapper ion-row {
    width: 165px;
    margin: 0 auto;
    border-radius: 20px;
    border: 1px solid var(--ion-color-gray);
    margin-bottom: 25px;
    color: var(--ion-color-gray);
}

.validate-button-wrapper ion-row ion-col div ion-icon {
    font-size: 19px;
    color: var(--ion-color-gray);
}

.validate-button-wrapper ion-row ion-col.verified-right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: var(--okx-small-text-font-size);
    flex-grow: 3;
 }
.content-spinner {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 6;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0.3);
}
.loyalty-content {
	text-align: center;
	padding: 20px;
}

.qr-holder {
	margin-top: 40px;
}

.loyalty-content h1 {
	margin-top: 0px;
	padding-top: 0px;
	font-size: 60px;
	font-weight: bold;
}

.loyalty-content.scan h2 {
	margin-top: 20px;
}

.loyalty-content h2 {
	margin-top: 0px;
	font-size: 15px;
	font-weight: bold;
	text-transform: uppercase;
}

.loyalty-content ion-note {
	display: block;
	margin-bottom: 20px;
	margin: 0 auto;
	width: 55%;
}

.loyalty-content ion-button {
	margin: 0 20px 20px;
}

.loyalty-content ion-card {
	position: relative;
}

.loyalty-content ion-card-title {
	font-weight: bold;
	font-size: 22px;
}

.loyalty-content ion-card-subtitle {
	text-transform: none;
}

.loyalty-content .cover {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: var(--ion-color-dark);
	opacity: 0.6;
}

.loyalty-content .cover ion-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	font-size: 50px;
	margin-left: -25px;
	margin-top: -25px;
}

.loyalty-image {
	--background: #fff;
	border-radius: 0px;
	padding-bottom: 35%;
	background-repeat: no-repeat;
	background-position: center top;
	background-size: 100% auto;
	box-shadow: none;
}

.loyalty-image-01 {
    background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	padding-bottom: 35%;
}

.noQrCode {
    display: flex;
	justify-content: center;
	margin-top: 20px;
}

.order-intro-modal > .modal-wrapper {
	--max-height: 450px;
	width: 300px;
	border-radius: 15px;
}

.order-intro-modal > .modal-wrapper {
	height: 390px;
	width: 300px;
	border-radius: 15px;
}

.order-intro-modal > .modal-wrapper > .ion-page {
	justify-content: center;
}

.header {
	background-color: var(--ion-color-primary);
}

.modal-text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 15px;
	overflow-y: auto;
}

.modal-button {
	margin-top: 35px;
	padding: 0 15px;
}

.tabs {}
.tabs .segment-holder {
  position: relative;
}

/* .tabs ion-segment-button {
  min-width: 70px;
} */

.tabs ion-slides, ion-slide {
  height: calc(100vh - 179px);
}

.avatar-box {
    height: 97px;
    margin-bottom: 20px;
}
.input-avatar {
    display: none;
}
.profile-image-content {
    width: 96px;
    height: 96px;
    margin: auto;
}
.mbsc-mobiscroll .mbsc-fr-hdr, .mbsc-mobiscroll .mbsc-fr-btn {
	color: var(--ion-color-primary);
}

.mbsc-mobiscroll .mbsc-cal-days > div {
	color: var(--ion-color-primary);
	border-color: var(--ion-color-primary);
}

.mbsc-mobiscroll.mbsc-no-touch:not(.mbsc-ev-cal) .mbsc-cal-cell:not(.mbsc-disabled):hover .mbsc-cal-cell-txt, .mbsc-mobiscroll.mbsc-no-touch .mbsc-cal-day:not(.mbsc-disabled) .mbsc-cal-day-date:hover, .mbsc-mobiscroll .mbsc-cal-cell:focus .mbsc-cal-cell-txt {
	background: var(--ion-color-primary-tint);
}

.mbsc-mobiscroll .mbsc-fr-btn.mbsc-active, .mbsc-mobiscroll.mbsc-no-touch .mbsc-fr-btn-e:not(.mbsc-disabled):hover {
	background: var(--ion-color-primary-tint);
}

.mbsc-mobiscroll .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
	background: var(--ion-color-primary);
}

.date-picker-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.date-picker-label {
	font-size: 12px;
	font-weight: 400;
	color: #000000;
	display: block;
	margin-top: 10px;
	margin-left: 2px;
	transition: .1s ease-in;
}

.date-picker-label--red {
	color: var(--ion-color-primary);
}

.data-picker-input {
	border: 0;
	outline: 0;
	color: var(--ion-color-dark);
	padding: 10px 8px 10px 0;
}


.mbsc-material .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
	/* background: var(--ion-color-primary); */
	background: rgba(255, 26, 75, .9);
	color: #fff;
}

.mbsc-material .mbsc-fr-btn {
	color: var(--ion-color-primary);
}

.mbsc-ios .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
	background: var(--ion-color-primary);
	border-color: var(--ion-color-primary);
}

.mbsc-mobiscroll .mbsc-sc-whl-l {
	display: none;
}
.mbsc-comp.time-picker {
  width: 100%;
  color: var(--ion-color-dark);
  padding: 10px 8px 10px 0;
  border: 0;
  border-bottom: 1px solid;
  border-color: var(--ion-item-border-color,var(--ion-border-color,var(--ion-color-step-150,rgba(0,0,0,0.13))));
  outline: none;
}

.mbsc-comp.time-picker::placeholder {
  color: var(--ion-color-dark);
  opacity: 0.33;
}

.mbsc-ios .mbsc-sc-itm-sel, .mbsc-ios .mbsc-sc-whl-gr-3d {
  /* color: #000; */
  color: var(--ion-color-primary,#3880ff);
}

.mbsc-material .mbsc-sc-lbl {
  color: var(--ion-color-primary,#3880ff);
}

.mbsc-fr-btn1 .mbsc-fr-btn-e .mbsc-fr-btn {
  color: var(--ion-color-primary,#3880ff);
}

/* .mbsc-sc-itm .mbsc-btn-e .mbsc-sc-itm-sel {
  color: var(--ion-color-primary,#3880ff);
} */

.mbsc-material .mbsc-fr-btn-cont .mbsc-fr-btn {
  color: var(--ion-color-primary,#3880ff);
}

.mbsc-fr-btn-cont {
  display: flex !important;
  justify-content: space-between;
}

.mbsc-sc-itm-sel {
  color: var(--ion-color-primary,#3880ff);
  font-weight: 600;
}

.time-picker-label {
  font-size: 9px;
  color: var(--okx-normal-text-color);
  margin-left: 2px;
}

.mbsc-mobiscroll .mbsc-sc-lbl {
  color: var(--ion-color-primary,#3880ff); 
}

.mbsc-material .mbsc-sc-whl-l {
  display: none;
}

.mbsc-select-input.mbsc-control {
  width: 100%;
  background: transparent !important;
  color: var(--ion-color-dark);
  padding: 10px 8px 10px 15px;
  border: 0;
  outline: none;
}

.mbsc-select-input.mbsc-control::placeholder {
  color: var(--ion-color-dark);
  opacity: 0.33;
}

.mbsc-ios .mbsc-fr-btn {
  color: var(--ion-color-primary,#3880ff);
  font-size: 17px;
}

.select-picker-label {
  font-size: 9px;
  color: var(--okx-normal-text-color);
  margin-left: 2px;
}

.mbsc-fr-btn-w .mbsc-fr-btn-s {
  border: 0;
}

.mbsc-ios.mbsc-fr-center .mbsc-fr-btn-w {
  border: 0;
}

.mbsc-ios.mbsc-fr-center .mbsc-fr-btn-cont {
  border: 1px solid var(--ion-item-border-color,var(--ion-border-color,var(--ion-color-step-150,rgba(0,0,0,0.13))));
}

.mbsc-ios .mbsc-sc-whl-gr-3d .mbsc-sc-itm-3d {
  color: #a3a3a3;
}

.mbsc-ios .mbsc-sc-itm-sel {
  color: #000;
  color: var(--ion-color-primary,#3880ff) !important;
}
.feedback-label {
    font-size: 10px;
    color: var(--ion-feedback-label);
    margin: 5px;
    font-weight: bold;
  }
  
  .star-holder {
    padding: 10px 0px;
  }
  
  .star {
    font-size: 26px;
    color: var(--ion-feedback-star);
    margin-right: 15px;
  }
  
  .box-holder-top {
    margin-top: 20px;
  }

  .separator {
    height: 1px;
    background-color: var(--ion-color-form-label-dark);
    margin: 0 5px;
}

.native-textarea.sc-ion-textarea-ios  {
  padding-inline-start: 5px;
}

.commentService {
  height: 50px;
}

.commentTech {
  height: 160px;
}

ion-popover.customSelectOptions .popover-wrapper .popover-content ion-select-popover ion-list {
  margin-bottom: 0px;
}

ion-popover.customSelectOptions .popover-wrapper .popover-content ion-select-popover ion-list ion-label{
  margin-left: 10px;
}

.history-content {
    text-align: center;
    padding: 20px;
}

.history-item {
    border-bottom: 1px solid var(--ion-separator-color);
    text-transform: uppercase;
}

.history-item h2 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 15px;
    font-weight: bold;
}

.history-item ion-col:first-child {
    text-align: left;
}

.history-item ion-col p:first-child {
    color: var(--ion-color-primary);
    font-size: 8px;
    margin-bottom: 8px;
    font-weight: bold;
}

.history-item ion-col p:last-child {
    color: var(--ion-color-medium);
    font-size: 9px;
    margin-top: 2px;
    margin-bottom: 10px;
}

.history-item ion-col:last-child p {
    text-align: right;
    font-size: 15px;
    margin-top: 25px;
    font-weight: bold;
    color: var(--ion-color-tertiary);
}


.gm-holder {
	position: absolute;
	left: 0; right: 0; top: 0; bottom: 0;
}

.map-holder {
	position: relative;
	height: 100%;
}

.map {
    width: 100%;
    height: 100%;
}

.gm-style .map-location-holder {
	z-index: 9999999;
}

.map-location-holder {
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
    top: 60%;
    background-color: var(--ion-color-box-holder);
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	z-index: 1;
}

.location-section ion-item {
    --color: var(--ion-color-dark);
    --padding-start: 0px;
    --border-color: var(--ion-separator-color);
}

.location-section h2 {
    margin-left: 10px;
}

.location-section p {
    margin-left: 10px;
    color: var(--ion-color-dark);
    font-size: 11px;
    line-height: 20px;
}

.location-info  {
    width: 100%;
    display: grid;
    /* grid-template-rows: repeat(5, 1fr); */
    grid-template-columns: repeat(2, 1f);
    padding-left: 10px;
    font-size: 11px;
    line-height: 20px;
}

ion-list.location-section ion-item:last-child {
    --padding-start: 0px;
    --border-width: 0px;
    --border-color: #fff;
}

.h-padded {
	padding: 0px 10px
}

.time {
    display: flex;
}

.time.time-0 {
    order: 1;
}
.time.time-1 {
    order: 3;
}
.time.time-2 {
    order: 5;
    grid-column-end: span 2;
}
.time.time-3 {
    order: 7;
    grid-column-end: span 2;
}
.time.time-4 {
    order: 9;
}
.time.time-5 {
    order: 2;
}
.time.time-6 {
    order: 4;
}

.time:nth-last-child(-n+3) {
    margin-right: 0px;
}

.day {
    flex: 0 0 35px;
}
 


.square {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.square:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.content {
    position: absolute;
    display: flex;
    flex-flow: column;
    background-color: var(--ion-color-light);
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.soc-icon {
    display: flex;
    border-radius: 50%;
    width: 40%;
    height: 40%;
    background-color: var(--ion-color-primary);
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
    font-size: 40px;
}

.soc-label {
    font-size: 20px;
    font-weight: bold;
    color: var(--ion-color-dark);
}

.noSocial {
    display: flex;
    justify-content: center;
}

ion-input.okx-password,
ion-input.android-okx-password {
	flex-direction: row-reverse;
}
ion-input.okx-password input {
	padding-top: 25px!important;
}
ion-input.android-okx-password input {
	padding-top: 28px!important;
}
.password-label,
.android-password-label {
	position: absolute;
	left: 0;
	margin-top: 0;
}
ion-input.android-okx-password.has-focus ion-label.android-password-label,
ion-input.android-okx-password.has-value ion-label.android-password-label {
	top: 0;
}
ion-input.okx-password ion-button {
	margin-top: 20px;
	--background: transparent;
	--background-activated: transparent;
	--background-focused: transparent;
	--background-hover: transparent;
	--ripple-color: transparent;
}
ion-input.android-okx-password ion-button {
	margin-top: 20px;
}
.poster.login-01 {
	background-size: 90% auto;
	position: relative;
	background-color: #fff;
	background-image: url(/static/media/login-02.f09b1486.png);
	background-position: 0 125%;
	z-index: 1;
}
.poster.login-01::after {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: inherit;
	background-image: url(/static/media/login-01.8aa96b83.png);
	background-color: transparent;
	background-position: 50% 0;
	background-size: 180px auto;
	z-index: 2;
}
.login-icon {
	margin-right: 15px;
	margin-top: 29px;
	color: var(--ion-color-primary);
}
.login-button {
	margin-top: 40px;
}
.text-link {
	margin-top: 0;
	height: 20px;
	margin-bottom: 40px;
}
.forgot-pass {
	font-size: 10px !important;
}
.login-header {
	color: var(--ion-color-dark);
}
.login-input ion-label,
.android-login-input ion-label,
.password-label,
.android-password-label {
	position: absolute;
}
.android-login-input ion-label,
.android-password-label {
	top: 12px;
}
.android-login-input.has-focus ion-label,
.android-login-input.has-value ion-label {
	top: 0;
}
.password-label{ 
	margin-top: 0;
}
.login-input input {
	padding-top: 25px!important;
}
.android-login-input input {
	padding-top: 28px!important;
}
.login-input button,
.android-login-input button {
	margin-top: 18px!important;
}
.toggle {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}
.okx-validate-input-wrapper {
	display: flex;
	width: 100%;
}

.okx-validate-input-wrapper .okx-validate-input-holder {
    flex: 1 1 auto;
    padding-bottom: 5px;
}

.okx-validate-input-wrapper .okx-validate-input-holder ion-datetime {
    --padding-start: 0;
    --padding-top: 15px;
}
.okx-validate-input-wrapper .okx-validate-input-holder p {
    font-size: 13px;
    font-weight: 500;
    margin: 21px 0 0 0;
    display: flex;
    padding-left: 2px;
    color: inherit;
}

.okx-validate-input-wrapper .okx-validate-input-holder ion-label {
    font-size: 11px;
}

.okx-validate-input-wrapper ion-button {
    flex: 1 0 auto;
    padding-top: 10px;
}

.date-picker-wrapper.modal .data-picker-input {
    padding-top: 15px;
}

.date-picker-wrapper.modal ion-label {
    font-size: 8px;
}

.date-picker-modal-label {
	font-size: 9px;
	/* color: var(--ion-color-dark); */
	margin-left: 2px;
	transition: .1s ease-in;
}
.validate-modal > .modal-wrapper {
	max-height: 72vh;
	width: 80vw;
    border-radius: 15px;
    text-align: center;
}

.validate-modal > .modal-wrapper > .ion-page {
	min-width: 100%;
	padding: 15px 25px;
	overflow: auto;
}

.validate-modal > .modal-wrapper > .ion-page h2 {
	font-size: 20px;
	margin-top: 4px;
	margin-bottom: 4px;
}

.validate-modal > .modal-wrapper > .ion-page ion-icon {
	position: absolute;
	right: 0;
	font-size: 15px;
}

.validate-modal ion-item {
	--min-height: 20px;
	--inner-padding-end: 0;
}

.modal-content {
	margin-top: 20px;
}

ion-header.static {
	margin-bottom: -48px;
	z-index: 5;
}

ion-toolbar.primary-toolbar {
	--min-height: 80px;
	--background: var(--ion-color-primary) url(/static/media/logo-main.f7c9e118.png) no-repeat 50% 0/auto 80%;
}

ion-header.static ion-toolbar:last-child {
	--min-height: var(--ion-toolbar-min-height);
}

.image-button {
	flex: 1 1;
	background: transparent;
}

.menu-enabled .list-md {
	padding-top: 0;
	padding-bottom: 0;
}

.drawer-menu .header-md, .drawer-menu .header-md {
	background-color: var(--ion-color-primary);
}

.drawer-menu .header-md {
	padding: 32px 32px 0;
}

.drawer-menu .title-default {
	text-transform: uppercase;
	font-weight: bold;
}

.drawer-menu > .ion-color {
	--padding-start: 32px;
	--padding-end: 32px;
}

.drawer-menu .title-default {
	padding-inline-start: 0;
}

.drawer-menu .nav-item {
	--padding-start: 0;
	--min-height: 24px;
}

.drawer-menu .nav-icon, .drawer-menu .nav-label {
	margin-top: 8px;
	margin-bottom: 8px;
}

.drawer-menu .header-ios {
	padding: 20px 25px 0px 0px;
	background-color: var(--ion-color-primary);
}

.header-ios ion-toolbar:last-child{
	--border-width: 0 0 0px
} 

.footer-ios ion-toolbar:first-child {
    --border-width: 0 0 0px;
}

ion-footer ion-toolbar.app-android-version:last-child {
	padding: 0px 0px 0px 15px;
}	

ion-footer ion-toolbar.app-ios-version:last-child {
	padding: 0px;
}	

ion-footer ion-label.title{
	padding: 0px 0px 0px 32px;
}	

ion-footer ion-label.title-andorid{
	padding: 0px 0px 0px 20px;
}

.logo-5l {
	position: absolute;
	right: 16px;
	bottom: 8px;
}

.logo-5l > div {
	width: 70px;
	padding-top: 45%;
	background: url(/static/media/5loyalty_logo.5fc839f4.svg) transparent no-repeat 50% 50%;
	background-size: 100% auto;
}
@media screen {
/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  src: url(/static/media/Lato-Regular.79164ee5.ttf);
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  src: url(/static/media/Lato-Bold.401bd636.ttf);
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: normal;
  src: url(/static/media/Lato-Italic.9c783784.ttf);
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: bold;
  src: url(/static/media/Lato-BoldItalic.f9d1ae3e.ttf);
}

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #ee161f;
  --ion-color-primary-rgb: 238, 22, 31;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #b9030b;
  --ion-color-primary-tint: #f63038;

  /** secondary **/
  --ion-color-secondary: #72c042;
  --ion-color-secondary-rgb: 114, 192, 66;
  --ion-color-secondary-contrast: #fff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #2a6903;
  --ion-color-secondary-tint: #bbf795;

  /** tertiary **/
  --ion-color-tertiary: #f79518;
  --ion-color-tertiary-rgb: 247, 149, 24;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #6b4007;
  --ion-color-tertiary-tint: #f1ba71;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  	/** gray **/
	--ion-color-gray: #9b9b9b;
	--ion-color-gray-rgb: 155, 155, 155;
	--ion-color-gray-contrast: #ffffff;
	--ion-color-gray-contrast-rgb: 255, 255, 255;
	--ion-color-gray-shade: #858585;
	--ion-color-gray-tint: #a1a1a1;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f8f8f8;
  --ion-color-light-rgb: 248, 248, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** white **/
  --ion-color-white: #fff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #c0c0c0;
  --ion-color-white-contrast-rgb: 192, 192, 192;
  --ion-color-white-shade: #d7d8da;
  --ion-color-white-tint: #f5f6f9;

  /** fonts **/
  --ion-font-family: "Lato";
  --ion-font-size: 16px;

  /** okx vars **/
  --okx-small-text-font-size: 13px;

  /** form **/
  --ion-color-form-label: #b0abab;
  --ion-color-form-label-dark: #1e191a;
  --ion-color-note: #cbc6c6;

  /** custom values **/
  --ion-color-title2: #1073d0;
  --ion-color-box-holder: #fff;
  --ion-separator-color: #e6e6e6;
  --ion-feedback-label: #727272;
  --ion-top-small: 5px;
  --ion-top-medium: 30px;
  --ion-feedback-star: #9ba1a5;

  /** original theme file colors **/
  --ion-headings-font-weight: 300;

  --ion-color-angular: #ac282b;
  --ion-color-communication: #8e8d93;
  --ion-color-tooling: #fe4c52;
  --ion-color-services: #fd8b2d;
  --ion-color-design: #fed035;
  --ion-color-workshop: #69bb7b;
  --ion-color-food: #3bc7c4;
  --ion-color-documentation: #b16be3;
  --ion-color-navigation: #6600cc;

  --ion-padding: 32px;
  --ion-toolbar-min-height: 48px;
}

}
.ion-color-favorite {
	--ion-color-base: #69bb7b;
	--ion-color-base-rgb: 105, 187, 123;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-twitter {
	--ion-color-base: #1da1f4;
	--ion-color-base-rgb: 29, 161, 244;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: #1a8ed7;
	--ion-color-tint: #34aaf5;
}

.ion-color-google {
	--ion-color-base: #dc4a38;
	--ion-color-base-rgb: 220, 74, 56;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: #c24131;
	--ion-color-tint: #e05c4c;
}

.ion-color-vimeo {
	--ion-color-base: #23b6ea;
	--ion-color-base-rgb: 35, 182, 234;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: #1fa0ce;
	--ion-color-tint: #39bdec;
}

.ion-color-facebook {
	--ion-color-base: #3b5998;
	--ion-color-base-rgb: 59, 89, 152;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: #344e86;
	--ion-color-tint: #4f6aa2;
}

.ion-color-primary-shade {
	--ion-color-base: var(--ion-color-primary-shade);
	--ion-color-base-rgb: 185, 3, 11;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: var(--ion-color-primary-shade);
	--ion-color-tint: var(--ion-color-primary);
}

.ion-color-white {
		--ion-color-base: var(--ion-color-white);
		--ion-color-base-rgb: var(--ion-color-white-rgb);
		--ion-color-contrast: var(--ion-color-white-contrast);
		--ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
		--ion-color-shade: var(--ion-color-white-shade);
		--ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-gray, .ion-color-grey {
	--ion-color-base: var(--ion-color-gray);
	--ion-color-base-rgb: var(--ion-color-gray-rgb);
	--ion-color-contrast: var(--ion-color-gray-contrast);
	--ion-color-contrast-rgb: var(--ion-color-gray-contrast-rgb);
	--ion-color-shade: var(--ion-color-gray-shade);
	--ion-color-tint: var(--ion-color-gray-tint);
}

/* Shared Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the CSS variables found in Ionic's source files.
// To view all the possible Ionic variables, see:
// https://beta.ionicframework.com/docs/theming/css-variables#ionic-variables
*/

.md {
	--ion-toolbar-background: var(--ion-color-primary);
	--ion-toolbar-color: #fff;
	--ion-toolbar-color-activated: #fff;
}

h3 {
	font-size: 16px;
	text-align: center;
	color: var(--ion-color-tertiary);
	margin-bottom: 30px;
}

h4 {
	font-size: 15px;
	color: var(--ion-color-form-label-dark);
}

.title2 {
	color: var(--ion-color-title2);
	margin: 40px 0 20px;
}

.box-holder {
	background-color: var(--ion-color-box-holder);
	border-radius: 5px;
	padding: 0px 15px;
}


.pad10 {
	padding: 10px;
}

.pad20 {
	padding: 20px;
}

.pad40b {
	padding-bottom: 40px;
}

.pad5l {
	padding-left: 5px;
}

.pad17l {
	padding-left: 17px;
}

.box-holder ion-item {
	--padding-start: 0px;
	--border-color: var(--ion-separator-color)
}

.box-holder ion-list {
	margin-bottom: 10px
}

ion-list ion-item:last-child:not(.single-item) {
	--padding-start: 0px;
	--border-width: 0px;
	--border-color: #fff;
}

.single-item {
	margin-bottom: 10px;
}

ion-label h2 {
	color: var(--ion-color-form-label-dark);
}

ion-note {
	color: var(--ion-color-note);
	font-size: 12px;
}

ion-label.ion-text-wrap {
	line-height: 1 !important;
}

ion-item {
	--padding-start: 0;
}

ion-item ion-button {
	margin-bottom: 0;
	height: auto;
}

.float-input-holder {
	--padding-start: 0px;
	--border-color: var(--ion-color-form-label)
}

.top-small {
	margin-top: var(--ion-top-small);
}

.top-medium {
	margin-top: var(--ion-top-medium);
}

.verified-content {
	width: 190px;
	margin: 0 auto;
	text-align: center;
}

ion-button {
	--border-radius: 20px;
	--box-shadow: none;
	margin-bottom: 10px;
	height: 40px;
	font-size: 10px;
	font-weight: bold;
	text-transform: uppercase;
}

ion-button.ion-color-primary {
	--border-style: solid;
	--border-width: 2px;
	--border-color: var(--ion-color-primary);
}

ion-button.ion-color-white {
	--border-style: solid;
	--border-width: 2px;
	--border-color: var(--ion-color-white-contrast);
}

ion-button.validation {
	--background: var(--okx-color-white);
    --color: var(--ion-color-primary);
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--ion-color-primary);
}

ion-header ion-title {
	text-transform: uppercase;
	font-weight: bold;
}

.mixed-right, .mixed-right * {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	align-self: center;
	justify-self: flex-end;
}

.mixed-left, .mixed-left * {
	text-align: left;
	justify-content: flex-start;
	align-items: center;
	align-self: center;
	justify-self: flex-start;
}

.lefted, .lefted * {
	text-align: left;
	justify-content: flex-start;
	align-items: flex-start;
	align-self: flex-start;
	justify-self: flex-start;
}

.mixed-left , .mixed-left * .grow{ 
	flex-grow: 0.5;
}

.contrast-separator {
	display: block;
	height: 0;
	line-height: 0;
	border: 1px solid rgba(255,255,255,0.3);
	margin: 16px 0;
}

.contrast-separator.contrast-separator-mini {
	margin: 12px 0;
}

.poster {
	padding-top: 65%;
	background-color: transparent;
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.no-padding {
	padding: 0;
}

.text-link {
	--ion-toolbar-background: transparent;
	--background-hover: transparent;
	--background-activated: transparent;
	--background-focused: transparent;
	--color: var(--ion-color-medium);
	--color-hover: var(--ion-color-dark);
	--color-activated: var(--ion-color-dark);
	--color-focused: var(--ion-color-dark);
	--ripple-color: #fff;
	text-transform: unset;
	font-size: inherit;
}

.segment-holder {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.segment-holder ion-segment {
	flex: 0 0 auto;
}

.segment-holder .segment-content {
	flex: 1 1 auto;
	overflow: hidden;
	overflow-y: auto;
}

ion-segment-button {
	height: 50px;
	border-radius: 0px;
	color: var(--ion-color-dark);
	border: 0 none;
	border-bottom: 4px solid var(--ion-color-primary-contrast);
	background-color: var(--ion-color-primary-contrast);
	font-weight: bold;
}

ion-segment-button.segment-button-checked {
	border-bottom-color: var(--ion-color-primary);
	color: var(--ion-color-primary);
}

.clickable {
	cursor: pointer;
}

.field-error {
	color: var(--ion-color-primary);
	font-size: 11px;
	margin-bottom: 10px;
}

.small-text {
	font-size: var(--okx-small-text-font-size);
	color: inherit;
}

